import React from "react";
import { motion } from 'framer-motion';

const ServiceCard = ({ service }) => {
  const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        duration: 0.5,
        ease: "easeOut"
      } 
    }
  };

  return (
    <motion.div
      variants={cardVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      whileHover={{ scale: 1.2   }}
      className="relative overflow-hidden rounded-lg shadow-lg h-72 group"
    >
      <div 
        className="absolute inset-0 bg-cover bg-center transition-transform duration-300 ease-in-out group-hover:scale-105"
        style={{ backgroundImage: `url(${require(`../assets/images/${service.image}`)})` }}
      />
      <div className="absolute inset-0 bg-black opacity-20 group-hover:opacity-50 transition-opacity duration-300 ease-in-out" />
      <div className="absolute inset-x-0 bottom-0 min-h-[4rem] max-h-[4rem] group-hover:max-h-full h-auto bg-black bg-opacity-75 transition-all duration-300 ease-in-out flex flex-col justify-end p-4">
        <h3 className="text-lg sm:text-xl font-semibold text-white mb-2 transition-transform duration-300 ease-in-out group-hover:translate-y-[-0.5rem]">
          {service.heading}
        </h3>
        <ul className="text-white text-sm opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100 overflow-hidden list-disc pl-5">
          {service.bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

export default ServiceCard;