import React from "react";
import { motion } from 'framer-motion';
import backgroundImage from "../assets/images/background11.jpg";
import ContactForm from "./ContactForm";

const AnyQuestionsSection = () => {
  const textVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.9,
        ease: "easeOut"
      }
    }
  };

  return (
    <section
      className="py-20 bg-cover bg-center relative"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-wrap -mx-4">
          {/* Left Side - Textual Content */}
          <motion.div 
            className="w-full md:w-1/2 px-4 mb-8 md:mb-0"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
          >
            <motion.h2 variants={textVariants} className="text-4xl md:text-5xl font-bold mb-6 text-white">
              Any Questions?
            </motion.h2>
            <motion.p variants={textVariants} className="text-xl text-gray-300 mb-8">
              Whether you have a question, want to start a project or simply
              want to connect.
            </motion.p>
            <motion.p variants={textVariants} className="text-xl text-gray-300 mb-8">
              Don't hesitate to send us a message through our contact form.
            </motion.p>
            <motion.div variants={textVariants} className="mb-8">
              <h3 className="text-2xl font-semibold text-white mb-4">
                Why Choose Us?
              </h3>
              <ul className="text-gray-300 space-y-2">
                <li>✓ Expert team with 40+ years of combined experience.</li>
                <li>✓ Customized solutions for your needs.</li>
                <li>✓ Less than 24 hours response</li>
                <li>✓ Competitive pricing</li>
              </ul>
            </motion.div>
          </motion.div>

          {/* Right Side - Contact Form */}
          <div className="w-full md:w-1/2 px-4">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AnyQuestionsSection;