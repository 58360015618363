import React from "react";
import { motion } from "framer-motion";
import ServiceCard from "./ServiceCard";
import data from "../data.json";

const OurServicesSection = () => {
  const { servicesList } = data;

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-3xl md:text-4xl font-bold text-gray-800 text-center mb-6"
        >
          Our Services
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          className="text-xl text-gray-600 text-center mb-12 max-w-3xl mx-auto"
        >
          Explore our comprehensive range of electrical power system solutions
          designed to meet your specific needs. From power system studies to
          automation and data analytics, we offer cutting-edge services to
          optimize your electrical infrastructure.
        </motion.p>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 md:px-8 lg:px-16"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
        >
          {servicesList.map((service) => (
            <div key={service.id} className="flex justify-center">
              <div className="w-full max-w-sm cursor-pointer">
                <ServiceCard service={service} />
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default OurServicesSection;
