import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { motion, AnimatePresence } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import data from "../data.json";
import wallpaper1 from "../assets/images/background11.jpg";


const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [zoomDirection, setZoomDirection] = useState(1); // 1 for zoom in, -1 for zoom out
  const { servicesList } = data;

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 7000);
    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setIsAnimating(true);
    setZoomDirection(prev => prev * -1);
    setTimeout(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % servicesList.length);
      setIsAnimating(false);
    }, 500);
  };

  const prevSlide = () => {
    setIsAnimating(true);
    setZoomDirection(prev => prev * -1);
    setTimeout(() => {
      setCurrentSlide((prevSlide) => (prevSlide - 1 + servicesList.length) % servicesList.length);
      setIsAnimating(false);
    }, 500);
  };

  const backgroundVariants = {
    zoomIn: {
      scale: 1.05,
      transition: { duration: 3.5, ease: "easeInOut" }
    },
    zoomOut: {
      scale: 1,
      transition: { duration: 3.5, ease: "easeInOut" }
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className="relative w-full h-screen overflow-hidden" {...handlers}>
      <motion.div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${wallpaper1})`,
        }}
        animate={zoomDirection === 1 ? "zoomIn" : "zoomOut"}
        variants={backgroundVariants}
      />

      <div className="absolute inset-y-0 left-0 w-full flex items-center">
        <div className="w-full max-w-lg mx-auto md:mx-16 lg:mx-24">
          <div
            className="bg-black bg-opacity-50 backdrop-filter backdrop-blur-md p-8 rounded-lg transition-all duration-500 ease-in-out transform"
            style={{
              opacity: isAnimating ? 0 : 1,
              transform: isAnimating ? "translateY(20px)" : "translateY(0)",
            }}
          >
            <h2 className="text-3xl md:text-4xl text-white mb-4">
              {servicesList[currentSlide].heading}
            </h2>
            <p className="text-lg md:text-xl text-gray-200 mb-6">
              {servicesList[currentSlide].shortDescription}
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <Link
                to="ourServices"
                smooth={true}
                duration={500}
                className="bg-white hover:bg-custom-red cursor-pointer text-black py-2 px-4 rounded transition duration-300 text-center"
              >
                View Services
              </Link>
              <Link
                to="anyQuestions"
                smooth={true}
                duration={500}
                className="bg-custom-blue hover:bg-custom-red cursor-pointer text-white py-2 px-4 rounded transition duration-300 text-center"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:block">
        <button
          onClick={prevSlide}
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-custom-red hover:text-white text-black p-2 rounded-full z-20 transition-colors duration-300"
        >
          &#10094;
        </button>
        <button
          onClick={nextSlide}
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-custom-red hover:text-white text-black p-2 rounded-full z-20 transition-colors duration-300"
        >
          &#10095;
        </button>
      </div>
    </div>
  );
};

export default Slideshow;