import React from "react";
import Slideshow from "../components/Slideshow";
import OurServicesSection from "../components/OurServicesSection";
import WhyChooseUsSection from "../components/WhyChooseUsSection";
// import TestimonialSection from "../components/TestimonialSection";
import AnyQuestionsSection from "../components/AnyQuestionsSection";
import FooterSection from "../components/FooterSection";

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        <section id="home">
          <Slideshow />
        </section>
        <section id="ourServices">
          <OurServicesSection />
        </section>
        <section id="whyChooseUs">
          <WhyChooseUsSection />
        </section>
        {/* <section id="testimonials">
          <TestimonialSection />
        </section> */}
        <section id="anyQuestions">
          <AnyQuestionsSection />
        </section>
      </main>
      <FooterSection />
    </div>
  );
};
export default Home;
