import React from 'react';
import { motion } from 'framer-motion';
import data from '../data.json';

const WhyChooseUsSection = () => {
  const { reasonsList } = data;

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.8,
        ease: "easeOut"
      } 
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50, scale: 0.9 },
    visible: { 
      opacity: 1, 
      y: 0, 
      scale: 0.9,
      transition: { 
        duration: 0.5,
        ease: "easeOut"
      } 
    },
    hover: {
      scale: 1.1,
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
      transition: {
        duration: 0.2
      }
    }
  };

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          variants={textVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          className="text-3xl md:text-4xl font-bold text-gray-800 text-center mb-6"
        >
          Why Choose Us
        </motion.h2>
        <motion.p 
          variants={textVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          className="text-xl text-gray-600 text-center mb-12 max-w-3xl mx-auto"
        >
          Discover the advantages of partnering with our expert team. With decades of experience, a customer-centric approach, and a commitment to innovation, we deliver reliable and efficient solutions tailored to your unique requirements.
        </motion.p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {reasonsList.map((reason) => (
            <motion.div
              key={reason.id}
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              whileHover="hover"
              viewport={{ once: true, amount: 0.3 }}
              className="relative overflow-hidden rounded-lg shadow-lg bg-white transition-all duration-200 h-80 group border-2 border-transparent hover:border-custom-blue"
            >
              <div className="relative p-6 flex flex-col items-center text-center h-full justify-center">
                <div className="text-gray-800 mb-4 transition-transform duration-200 group-hover:scale-110 group-hover:text-custom-blue">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={reason.icon} />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold mb-3 text-gray-800 transition-all duration-200 group-hover:scale-110 group-hover:text-custom-blue">{reason.title}</h3>
                <p className="text-gray-600 transition-all duration-200 group-hover:scale-105 group-hover:text-custom-blue">{reason.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsSection;