import React from "react";
import companyIcon from "../assets/images/tppesLogo3.jpg";

const CompanyLogo = () => {
  return (
    <div className="flex items-center">
      <img
        src={companyIcon}
        alt="companyLogo"
        className="w-19 h-12 flex-shrink-0"
      />
      {/* <span className="text-2xl font-bold text-[#FFC107]">TPPES</span> */}
    </div>
  );
};

export default CompanyLogo;
